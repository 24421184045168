<template>
  <el-card shadow="never">
    <el-form :model="form" :rules="rules" ref="form" label-width="130px" size="mini">
      <el-row>
        <el-col :span="12">
          <el-form-item label="材料通知单编号">
            <el-input type="text" v-model="form.code"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出入库房" prop="cangku">
            <el-select v-model="form.cangku" placeholder="请选择所属仓库">
              <el-option v-for="item in cangkus_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-form-item label="材料名称" prop="cailiao">
            <el-select v-model="form.cailiao" placeholder="材料名称" @change="getCailiaoweightbyID">
              <el-option v-for="item in cailiaos_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="材料规格" prop="cailiaoguige">
            <el-select v-model="form.cailiaoguige" placeholder="材料规格" @change="getCailiaoweightbyID">
              <el-option v-for="item in cailiaoguiges_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="调配时间" prop="date">
            <el-date-picker
              type="date"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              v-model="form.date"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="合同内数量" prop="in_amount">
            <el-input v-model.number="form.in_amount"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="增量" prop="add_amount">
            <el-input v-model.number="form.add_amount"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="租金单价（元）" prop="zujin_danjia">
            <el-input
              v-model="form.zujin_danjia"
              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="租金合计（元）">
            <el-input v-model="zujin_hj" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="押金单价（元）" prop="yajin_danjia">
            <el-input
              v-model="form.yajin_danjia"
              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="押金合计（元）">
            <el-input v-model="yajin_hj" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="space-between" :gutter="20">
        <el-col :span="10">
          <el-form-item label="运费单价（元）" prop="yunfei_danjia">
            <el-input
              v-model="form.yunfei_danjia"
              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-checkbox v-model="is_baoche" border size="mini">包车</el-checkbox>
        </el-col>
        <el-col :span="10" v-if="!is_baoche">
          <el-form-item label="运费合计（元）">
            <el-input v-model="yunfei_hj" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10" v-if="is_baoche">
          <el-form-item label="包车运费（元）">
            <el-input
              v-model="yunfei_baoche"
              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="吊装费吨数">
            <el-input v-model="diaozhuangfei_dunshu" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="单价">
            <el-select v-model="diaozhuangfei_danjia">
              <el-option v-for="item in diaozhuangfei_danjia_options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="吊装费合计（元）">
            <el-input v-model="diaozhuangfei_hj" readonly></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item label="出板合计">
            <el-input v-model.number="chuban_hj" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="实际汇入（元）" prop="shiji_huiru">
            <el-input
              v-model="form.shiji_huiru"
              onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
              onafterpaste="this.value=this.value.match(/\d+\.?\d{0,2}/)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item label="说明" prop="jinpai_kehu">
            <el-checkbox border v-model="form.jinpai_kehu">金牌客户</el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-form-item prop="lengzhuang_lianmeng">
            <el-checkbox border v-model="form.lengzhuang_lianmeng">冷弯联盟</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" rows="3" v-model="form.remark"></el-input>
      </el-form-item>
      <el-form-item>
        <div style="float:right;">
          <el-button @click="resetForm('form')"> 取消 </el-button>
          <el-button type="primary" @click="submitForm('form')"> 确认修改 </el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  props: {
    cailiaotongzhidan_id_toadd: String,
    formData: Object
  },
  data() {
    return {
      form: {
        in_amount: 0,
        add_amount: 0,
        yunfei_danjia: 0,
        zujin_danjia: 0,
        yajin_danjia: 0
      },
      is_baoche: false,
      isValidationOk: false,
      cangkus_options: [],
      cailiaos_options: [],
      cailiaoguiges_options: [],
      cailiaoweights: [],
      current_cailiaoweight: 0,
      zujin_hj: 0,
      yajin_hj: 0,
      yunfei_hj: 0,
      yunfei_baoche: 0,
      diaozhuangfei_dunshu: 0,
      diaozhuangfei_hj: 0,
      chuban_hj: 0,

      diaozhuangfei_danjia_options: [
        {
          value: '10',
          label: '10元'
        },
        {
          value: '20',
          label: '20元'
        },
        {
          value: '20.4',
          label: '20.4元'
        },
        {
          value: '22',
          label: '22元'
        },
        {
          value: '22.6',
          label: '22.6元'
        },
        {
          value: '0',
          label: '0元'
        }
      ],
      diaozhuangfei_danjia: '10',

      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        code: [{ required: true, message: '请输入材料通知单编号', trigger: 'blur' }],
        cangku: [{ required: true, message: '请选择所属仓库', trigger: 'blur' }],
        cailiao: [{ required: true, message: '请选择材料名称', trigger: 'blur' }],
        cailiaoguige: [{ required: true, message: '请选择材料规格', trigger: 'blur' }],
        in_amount: [{ required: true, message: '请输入数量', trigger: 'blur' }, { type: 'number', message: '数量必须为数字' }],
        add_amount: [{ required: true, message: '请输入数量', trigger: 'blur' }, { type: 'number', message: '数量必须为数字' }],
        shiji_huiru: [{ required: true, message: '请输入数量', trigger: 'blur' }],
        date: [{ required: true, message: '请选择调配时间', trigger: 'blur' }]
      }
    }
  },
  computed: {
    zujin_danjia: function() {
      if (typeof this.form.zujin_danjia === 'undefined') {
        return 0
      } else {
        return this.form.zujin_danjia
      }
    },
    yajin_danjia: function() {
      if (typeof this.form.yajin_danjia === 'undefined') {
        return 0
      } else {
        return this.form.yajin_danjia
      }
    },
    yunfei_danjia: function() {
      if (typeof this.form.yunfei_danjia === 'undefined') {
        return 0
      } else {
        return this.form.yunfei_danjia
      }
    },

    in_amount: function() {
      if (typeof this.form.in_amount === 'undefined') {
        return 0
      } else {
        return this.form.in_amount
      }
    },
    add_amount: function() {
      if (typeof this.form.add_amount === 'undefined') {
        return 0
      } else {
        return this.form.add_amount
      }
    }
  },
  watch: {
    current_cailiaoweight: function() {
      this.yunfei_hj = this.yunfei_danjia * (this.in_amount + this.add_amount) * this.current_cailiaoweight
      this.diaozhuangfei_dunshu = (this.in_amount + this.add_amount) * this.current_cailiaoweight
      this.diaozhuangfei_hj = this.diaozhuangfei_dunshu * Number(this.diaozhuangfei_danjia)
      this.diaozhuangfei_dunshu = Number(this.diaozhuangfei_dunshu.toFixed(4))
      this.diaozhuangfei_hj = Number(this.diaozhuangfei_hj.toFixed(2))
      this.yunfei_hj = Number(this.yunfei_hj.toFixed(2))
      if (this.is_baoche) {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + Number(this.yunfei_baoche) + this.diaozhuangfei_hj
      } else {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + this.yunfei_hj + this.diaozhuangfei_hj
      }
    },
    zujin_danjia: function() {
      this.zujin_hj = this.zujin_danjia * (this.in_amount + this.add_amount) * 30
      this.zujin_hj = Number(this.zujin_hj.toFixed(2))
      if (this.is_baoche) {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + Number(this.yunfei_baoche) + this.diaozhuangfei_hj
      } else {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + this.yunfei_hj + this.diaozhuangfei_hj
      }
    },
    yajin_danjia: function() {
      this.yajin_hj = this.yajin_danjia * (this.in_amount + this.add_amount)
      this.yajin_hj = Number(this.yajin_hj.toFixed(2))

      if (this.is_baoche) {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + Number(this.yunfei_baoche) + this.diaozhuangfei_hj
      } else {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + this.yunfei_hj + this.diaozhuangfei_hj
      }
    },
    yunfei_danjia: function() {
      this.yunfei_hj = this.yunfei_danjia * (this.in_amount + this.add_amount) * this.current_cailiaoweight
      this.yunfei_hj = Number(this.yunfei_hj.toFixed(2))

      if (this.is_baoche) {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + Number(this.yunfei_baoche) + this.diaozhuangfei_hj
      } else {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + this.yunfei_hj + this.diaozhuangfei_hj
      }
    },
    yunfei_baoche: function() {
      // this.yunfei_baoche = Number(this.yunfei_baoche.toFixed(2))
      this.chuban_hj = this.zujin_hj + this.yajin_hj + Number(this.yunfei_baoche) + this.diaozhuangfei_hj
    },

    diaozhuangfei_danjia: function() {
      this.diaozhuangfei_dunshu = (this.in_amount + this.add_amount) * this.current_cailiaoweight
      this.diaozhuangfei_hj = this.diaozhuangfei_dunshu * Number(this.diaozhuangfei_danjia)
      this.diaozhuangfei_dunshu = Number(this.diaozhuangfei_dunshu.toFixed(4))
      this.diaozhuangfei_hj = Number(this.diaozhuangfei_hj.toFixed(2))

      if (this.is_baoche) {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + Number(this.yunfei_baoche) + this.diaozhuangfei_hj
      } else {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + this.yunfei_hj + this.diaozhuangfei_hj
      }
    },
    in_amount: function() {
      this.diaozhuangfei_dunshu = (this.in_amount + this.add_amount) * this.current_cailiaoweight
      this.diaozhuangfei_hj = this.diaozhuangfei_dunshu * Number(this.diaozhuangfei_danjia)
      this.zujin_hj = this.zujin_danjia * (this.in_amount + this.add_amount) * 30
      this.yajin_hj = this.yajin_danjia * (this.in_amount + this.add_amount)
      this.yunfei_hj = this.yunfei_danjia * (this.in_amount + this.add_amount) * this.current_cailiaoweight

      this.diaozhuangfei_dunshu = Number(this.diaozhuangfei_dunshu.toFixed(4))
      this.diaozhuangfei_hj = Number(this.diaozhuangfei_hj.toFixed(2))
      this.zujin_hj = Number(this.zujin_hj.toFixed(2))
      this.yajin_hj = Number(this.yajin_hj.toFixed(2))
      this.yunfei_hj = Number(this.yunfei_hj.toFixed(2))

      if (this.is_baoche) {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + Number(this.yunfei_baoche) + this.diaozhuangfei_hj
      } else {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + this.yunfei_hj + this.diaozhuangfei_hj
      }
    },
    add_amount: function() {
      this.diaozhuangfei_dunshu = (this.in_amount + this.add_amount) * this.current_cailiaoweight
      this.diaozhuangfei_hj = this.diaozhuangfei_dunshu * Number(this.diaozhuangfei_danjia)
      this.zujin_hj = this.zujin_danjia * (this.in_amount + this.add_amount) * 30
      this.yajin_hj = this.yajin_danjia * (this.in_amount + this.add_amount)
      this.yunfei_hj = this.yunfei_danjia * (this.in_amount + this.add_amount) * this.current_cailiaoweight

      this.diaozhuangfei_dunshu = Number(this.diaozhuangfei_dunshu.toFixed(4))
      this.diaozhuangfei_hj = Number(this.diaozhuangfei_hj.toFixed(2))
      this.zujin_hj = Number(this.zujin_hj.toFixed(2))
      this.yajin_hj = Number(this.yajin_hj.toFixed(2))
      this.yunfei_hj = Number(this.yunfei_hj.toFixed(2))

      if (this.is_baoche) {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + Number(this.yunfei_baoche) + this.diaozhuangfei_hj
      } else {
        this.chuban_hj = this.zujin_hj + this.yajin_hj + this.yunfei_hj + this.diaozhuangfei_hj
      }
    }
  },
  methods: {
    getCangkus() {
      this.$axios.get('/getCangkus/').then(res => {
        console.log('getCangkus list:', res.data)
        res.data.forEach(element => {
          let cangku = {}
          cangku.label = String(element.name)
          cangku.value = element.id
          this.cangkus_options.push(cangku)
        })
        console.log('cangkus_options', this.cangkus_options)
      })
    },
    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = element.id
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = element.id
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },
    getCailiaoweights() {
      this.$axios.get('/getCailiaoweights/').then(res => {
        this.cailiaoweights = res.data
        console.log('getCailiaoweights list:', res.data)
      })
    },
    getCailiaoweightbyID() {
      let cailiao = this.form.cailiao
      let cailiaoguige = this.form.cailiaoguige
      let weights = this.cailiaoweights
      console.log('cailiao is selected:', cailiao)
      console.log('cailiaoguige is selected:', cailiaoguige)
      console.log('this.cailiaoweights:', weights)
      for (let i = 0; i < weights.length; i++) {
        if (weights[i].cailiao === cailiao && weights[i].cailiaoguige === cailiaoguige) {
          this.current_cailiaoweight = weights[i].weight
        }
      }
      console.log('this.current_cailiaoweight2:', this.current_cailiaoweight)
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.form.yajin_hj = this.yajin_hj
          if (this.is_baoche) {
            this.form.yunfei_hj = this.yunfei_baoche
          } else {
            this.form.yunfei_hj = this.yunfei_hj
          }
          this.form.zujin_hj = this.zujin_hj
          this.form.diaozhuangfei_dunshu = this.diaozhuangfei_dunshu
          this.form.diaozhuangfei_hj = this.diaozhuangfei_hj
          this.$emit('submitForm', this.form)
          this.form = {}
          this.yajin_hj = 0
          this.zujin_hj = 0
          this.yunfei_hj = 0
          this.diaozhuangfei_hj = 0
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.yajin_hj = 0
      this.zujin_hj = 0
      this.yunfei_hj = 0
      this.diaozhuangfei_hj = 0
      this.$emit('resetForm')
    },

    fn1() {
      return new Promise(resolve => {
        setTimeout(() => {
          this.getCailiaoweights()
          resolve()
        }, 300)
      })
    },
    fn2() {
      return new Promise(resolve => {
        setTimeout(() => {
          this.initform()
          resolve()
        }, 300)
      })
    },
    fn3() {
      return new Promise(resolve => {
        setTimeout(() => {
          this.getCailiaoweightbyID()
          resolve()
        }, 300)
      })
    },
    fn4() {
      return new Promise(resolve => {
        setTimeout(() => {
          this.initHeji()
          resolve()
        }, 300)
      })
    },

    initform() {
      console.log('***********************************', this.formData)
      this.form = JSON.parse(JSON.stringify(this.formData)) //深拷贝，以防止修改父组件数据
      this.form.in_amount = Number(this.formData.in_amount)
      this.form.add_amount = Number(this.formData.add_amount)
      this.form.zujin_danjia = Number(this.formData.zujin_danjia)
      this.form.yajin_danjia = Number(this.formData.yajin_danjia)
      this.form.yunfei_danjia = Number(this.formData.yunfei_danjia)
      this.form.shiji_huiru = Number(this.formData.shiji_huiru)
      this.diaozhuangfei_dunshu = Number(this.formData.diaozhuangfei_dunshu)
      this.diaozhuangfei_hj = Number(this.formData.diaozhuangfei_hj)
      this.diaozhuangfei_danjia = this.diaozhuangfei_hj / this.diaozhuangfei_dunshu
    },

    initHeji() {
      this.zujin_hj = this.formData.zujin_hj
      this.yajin_hj = this.formData.yajin_hj
      this.yunfei_hj = this.formData.yunfei_hj
      this.diaozhuangfei_dunshu = this.formData.diaozhuangfei_dunshu
      this.diaozhuangfei_hj = this.formData.diaozhuangfei_hj
      this.chuban_hj = this.formData.chuban_hj
    }
  },
  created() {
    console.log('created ...................')
    this.getCangkus()
    this.getCailiaos()
    this.getCailiaoguiges()
    this.fn1()
      .then(this.fn2)
      .then(this.fn3)
      .then(this.fn4)
  }
}
</script>

<style lang="scss" scoped></style>
